import React, { useEffect, useState } from 'react'

export const useTrackers = trackers => {
	const [scripts, setScripts] = useState({})

	useEffect(() => {
		if (trackers?.yandex_metrika) {
			setScripts(prevState => ({
				...prevState,
				ya: `var yaParams = {/*Здесь параметры визита*/}; (function (d, w, c) { (w[c] = w[c] || []).push(function() { try { w.yaCounter${trackers.yandex_metrika.metrika_id} = new Ya.Metrika({id:${trackers.yandex_metrika.metrika_id}, clickmap:true, trackLinks:true, accurateTrackBounce:true,params:window.yaParams||{ }}); } catch(e) { } }); var n = d.getElementsByTagName("script")[0], s = d.createElement("script"), f = function () { n.parentNode.insertBefore(s, n); }; s.type = "text/javascript"; s.async = true; s.src = "https://mc.yandex.ru/metrika/watch.js"; if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "yandex_metrika_callbacks");`
			}))
		}
		if (trackers?.google_analytics) {
			setScripts(prevState => ({
				...prevState,
				ga: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); ga('create', '${trackers.google_analytics.google_analytics_id}', 'auto'); ga('send', 'pageview');`
			}))
		}
		if (trackers?.top_mail_ru) {
			setScripts(prevState => ({
				...prevState,
				mailru: `var _tmr=window._tmr||(window._tmr=[]);_tmr.push({id:"${trackers.top_mail_ru.mail_id}",type:"pageView",start:(new Date).getTime()}),function(a,b,c){if(!a.getElementById(c)){var d=a.createElement("script");d.type="text/javascript",d.async=!0,d.id=c,d.src=("https:"==a.location.protocol?"https:":"http:")+"//top-fwz1.mail.ru/js/code.js";var e=function(){var b=a.getElementsByTagName("script")[0];b.parentNode.insertBefore(d,b)};"[object Opera]"==b.opera?a.addEventListener("DOMContentLoaded",e,!1):e()}}(document,window,"topmailru-code");`
			}))
		}
		if (trackers?.facebook_pixel) {
			setScripts(prevState => ({
				...prevState,
				fb: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${trackers.facebook_pixel.facebook_id}');fbq('track', 'PageView');fbq('track', 'Lead');fbq('track', 'Purchase');`
			}))
		}
		if (trackers?.vk) {
			setScripts(prevState => ({
				...prevState,
				vk: `(window.Image ? (new Image()) : document.createElement("img")).src = "https://vk.com/rtrg?p=${trackers.vk.vk_id}&event=${trackers.vk.event}";`
			}))
		}
		if (trackers?.mgid_sensor) {
			setScripts(prevState => ({
				...prevState,
				mgid: `(function() {var d = document, w = window;w.MgSensorData = {cid:${trackers.mgid_sensor.counter_id}, lng:"ru", nosafari:true};var n = d.getElementsByTagName("script")[0];var s = d.createElement("script");s.type = "text/javascript";s.async = true;var dt = !Date.now?new Date().valueOf():Date.now();s.src = "//a.marketgid.com/mgsensor.js?d=" + dt;n.parentNode.insertBefore(s, n);})();`
			}))
		}
		if (trackers?.google_ads) {
			setScripts(prevState => ({
				...prevState,
				google_ads: {
					pixel_id: trackers.google_ads.pixel_id,
					code: `window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());  gtag('config', '${trackers.google_ads.pixel_id}');`,
					code2: `gtag('event', 'conversion', {'send_to': '${trackers.google_ads.pixel_id}/${trackers.google_ads.pixel_destination}'});`
				}
			}))
		}
		if (trackers?.google_tag_manager) {
			setScripts(prevState => ({
				...prevState,
				google_tag_manager: {
					tag: trackers.google_tag_manager.tag,
					code: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${trackers.google_tag_manager.tag}');`
				}
			}))
		}
		if (trackers?.tiktok_pixel) {
			setScripts(prevState => ({
				...prevState,
				tiktok: `!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=['page','track','identify','instances','debug','on','off','once','ready','alias','group','enableCookie','disableCookie'],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i='https://analytics.tiktok.com/i18n/pixel/events.js';ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement('script');o.type='text/javascript',o.async=!0,o.src=i+'?sdkid='+e+'&lib='+t;var a=document.getElementsByTagName('script')[0];a.parentNode.insertBefore(o,a)}; ttq.load('${trackers.tiktok_pixel.tiktok_id}');ttq.page();}(window, document, 'ttq');`
			}))
		}
		if (trackers?.bigoads_pixel) {
			setScripts(prevState => ({
				...prevState,
				bigoads: {
					pixel_id: trackers.bigoads_pixel.pixel_id,
					code: `window.bgdataLayer = window.bgdataLayer || [];function bge(){bgdataLayer.push(arguments);}bge('init', '${trackers.bigoads_pixel.pixel_id}');bge('event', '${trackers.bigoads_pixel.event}');`
				}
			}))
		}
	}, [trackers])

	return scripts
}
